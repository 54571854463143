<template>
    <div>
        <div class="sub-title">基础信息</div>
        <el-form :inline="true" label-width="190px" :model="form" class="demo-form-inline">
            <el-row>
                <el-col :span="6">
                    <el-form-item label="总面积（㎡）">
                        <el-input v-model="form.totalArea"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="总容积（吨）">
                        <el-input v-model="form.totalVolume"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="服务企业（家）">
                        <el-input v-model="form.totalServiceEnterprise"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="冷库个数（个）">
                        <el-input v-model="form.totalColdStorage"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            
            <!-- <el-row>
                <div class="sub-title">基地实时画面</div>
            </el-row>
            <el-row>
                <el-form-item label="画面名称1">
                    <el-input v-model="form.jidiVedio1Title"></el-input>
                </el-form-item>
                <el-form-item label="画面地址1">
                    <el-input style="width: 550px" v-model="form.jidiVedio1Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="画面名称2">
                    <el-input v-model="form.jidiVedio2Title"></el-input>
                </el-form-item>
                <el-form-item label="画面地址2">
                    <el-input style="width: 550px" v-model="form.jidiVedio2Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="画面名称3">
                    <el-input v-model="form.jidiVedio3Title"></el-input>
                </el-form-item>
                <el-form-item label="画面地址3">
                    <el-input style="width: 550px" v-model="form.jidiVedio3Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="画面名称4">
                    <el-input v-model="form.jidiVedio4Title"></el-input>
                </el-form-item>
                <el-form-item label="画面地址4">
                    <el-input style="width: 550px" v-model="form.jidiVedio4Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="画面名称5">
                    <el-input v-model="form.jidiVedio5Title"></el-input>
                </el-form-item>
                <el-form-item label="画面地址5">
                    <el-input style="width: 550px" v-model="form.jidiVedio5Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="画面名称6">
                    <el-input v-model="form.jidiVedio6Title"></el-input>
                </el-form-item>
                <el-form-item label="画面地址6">
                    <el-input style="width: 550px" v-model="form.jidiVedio6Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="画面名称7">
                    <el-input v-model="form.jidiVedio7Title"></el-input>
                </el-form-item>
                <el-form-item label="画面地址7">
                    <el-input style="width: 550px" v-model="form.jidiVedio7Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="画面名称8">
                    <el-input v-model="form.jidiVedio8Title"></el-input>
                </el-form-item>
                <el-form-item label="画面地址8">
                    <el-input style="width: 550px" v-model="form.jidiVedio8Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="画面名称9">
                    <el-input v-model="form.jidiVedio9Title"></el-input>
                </el-form-item>
                <el-form-item label="画面地址9">
                    <el-input style="width: 550px" v-model="form.jidiVedio9Url"></el-input>
                </el-form-item>
            </el-row> -->
        </el-form>
        
        <el-row class="ta_c">
            <el-button type="primary" style="min-width: 88px;" @click="onSaveInfo">保存</el-button>
        </el-row>

    </div>
</template>

<script>

export default {
    name: '',
    data(){
        return{
            form: {
                mp4: ''
            }
        }
    },
    
    mounted(){
        this.getInfo()
    },
    methods:{
        getInfo() {
            this.qa.datascreen10LoadDataBO({}).then(res => {
                if(res.respCode == '0000') {
                    this.form = res.data;
                }
            })
        },
        onSaveInfo() {
            this.qa.datascreen10DoSave(this.form).then(res => {
                if(res.respCode == '0000') {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.getInfo()
                }
            })
            
        },
    }
}
</script>

<style scoped>
    .sub-title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .avatar-uploader {
        width: 150px;
        height: 162px;
        border: 1px dashed #ccc;
        text-align: center;
    }
    .avatar-uploader .avatar {
        width: 150px;
        height: 162px;
    }
</style>