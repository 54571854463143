<template>
    <div>
        <div class="setting_content">
            <div class="setting_title" style="border-bottom: 1px solid #EFEFEF;">
                仓储冷链大屏设置
            </div>
            <div class="setting_content_content">
                <!-- 基本信息 -->
                <BasicInformation></BasicInformation>

                <div class="line"></div>
                <!--摄像头 -->
                <cameraList></cameraList>
                <div class="line"></div>
                <!-- <LeftNameTableLsit></LeftNameTableLsit>
                <div class="line"></div> -->
                <CropNameTableLsit></CropNameTableLsit>
                <div class="line"></div>
                <!-- 仓储企业 -->
                <QyTableLsit></QyTableLsit>
                <div class="line"></div>
                <!-- 仓储类型 -->
                <CctypeTableList></CctypeTableList>
                <div class="line"></div>
                <!-- 各乡镇冷链设施基础信息 -->
                <TownshipTableLsit></TownshipTableLsit>
                <div class="line"></div>
                 <!-- 企业贮藏用量统计 -->
                <WarehousingTableLsit></WarehousingTableLsit>
                <div class="line"></div>
                <TownsMapTableLsit></TownsMapTableLsit>                
                 <!--<div class="line"></div>
                 <XiaomiOrdersTableLsit></XiaomiOrdersTableLsit>
                <div class="line"></div>
                <PlotTableLsit></PlotTableLsit>
                <div class="line"></div>
                <TaskprogressTableLsit></TaskprogressTableLsit>
                <div class="line"></div>
                 -->
            </div>
        </div>
    </div>
</template>

<script>
import BasicInformation from './components/dataScreen10_llcc/basicInformation.vue'
import CameraList from './components/dataScreen10_llcc/cameraList.vue'
import LeftNameTableLsit from './components/dataScreen10_llcc/leftNameTableLsit.vue'
import CropNameTableLsit from './components/dataScreen10_llcc/cropNameTableLsit.vue'
import WarehousingTableLsit from './components/dataScreen10_llcc/warehousingTableLsit.vue'

import TownshipTableLsit from './components/dataScreen10_llcc/townshipTableLsit.vue'
import CctypeTableList from './components/dataScreen10_llcc/cctypeTableList.vue'
import QyTableLsit from './components/dataScreen10_llcc/qyTableLsit.vue'
import TownsMapTableLsit from './components/dataScreen10_llcc/townsMapTableLsit.vue'
// import XiaomiOrdersTableLsit from './components/dataScreen11_gwzl/xiaomiOrdersTableLsit.vue'
// import PlotTableLsit from './components/dataScreen5_potato_DDZY/plotTableLsit.vue'
// import TaskprogressTableLsit from './components/dataScreen5_potato_DDZY/taskprogressTableLsit.vue'

export default {
    name: '',
    data(){
        return{
        }
    },
    components: {
        BasicInformation,
        CameraList,
        LeftNameTableLsit,
        CropNameTableLsit,
        WarehousingTableLsit,
        QyTableLsit,
        CctypeTableList,
        TownshipTableLsit,
        TownsMapTableLsit,
        // XiaomiOrdersTableLsit,
        // PersonnelTableLsit,
        // AgriculturalMachineryTableLsit,
        // PlotTableLsit,
        // TaskprogressTableLsit,
        
    },
    mounted(){

    },
    methods:{
        
    }
}
</script>

<style scoped>
.menu_title {
    width: 100%;
    height: 146px;
    border: 1px solid #0091FF;
    background: rgba(25, 145, 252, 0.1);
    padding: 12px 20px;
    display: flex;
    box-sizing: border-box;
  }
  .descrip {
      font-size: 14px;
      color: #1991FC;
      flex-shrink: 0;
      width: 100px;
  }
  .descrip_content {
      font-size: 14px;
      color: rgba(29, 30, 31, 0.6);
      line-height: 14px;
  }
  .descrip_content >p {
      margin-bottom: 12px;
  }
  .setting_content {
      /* padding: 24px; */
      background: #fff;
  }
  .setting_title {
      padding: 24px;
      padding-bottom: 6px;
  }
  .setting_content_content {
      padding: 24px;
  }
  .bootom {
      width: calc(100% - 254px);
      height: 44px;
      position: fixed;
      bottom: 0;
      left: 230px;
      background: #fff;
      margin-left: -24px;
      text-align: center;
      line-height: 44px;
  }
  .sub-title {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .disease-box {
    position: relative;
    
    border: 1px solid #ccc;
    box-sizing: border-box;
    padding: 20px;
  }
  .el-icon-close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 50;
  }
  .line {
    width: 100%;
    height: 1px;
    margin: 30px 0;
    background: #ccc;
  }

  .avatar-uploader {
    width: 150px;
    height: 162px;
    border: 1px dashed #ccc;
    text-align: center;
  }
  .avatar-uploader .avatar {
    width: 150px;
    height: 162px;
  }
  .line {
    width: 100%;
    height: 1px;
    margin: 30px 0;
    background: #ccc;
  }
</style>